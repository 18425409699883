import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import style from './style.module.scss';

import { StatusSteps, TypeForm } from '../../type/form';
import { SendFormType } from '../../../components/module/Quiz';
import { useForm } from '../../context/form';
import Form from './From';

type Props = {
  sendForm: SendFormType;
  setStatusSteps: Dispatch<SetStateAction<StatusSteps>>;
};

const Phone: FC<Props> = ({ sendForm, setStatusSteps }) => {
  const { form, updateData, Send } = useForm();

  const [errorForms, setErrorForms] = useState<boolean>(false);
  const [send, setSend] = useState<boolean>(false);

  const checkFrom = (): boolean => {
    let isNext = true;

    if (!form.phone) {
      setErrorForms(() => !!!form.phone);
      isNext = false;
    } else {
      Send(form);
      setSend(true);
    }

    setStatusSteps((prev: StatusSteps) => ({
      ...prev,
      phone: isNext,
    }));

    return isNext;
  };

  useEffect(() => {
    sendForm(() => checkFrom);
  }, [form.phone, errorForms]);

  useEffect(() => {
    const bonus = form.bonus ? 1800 : 3000;
    const mezanica = form.mezanica?.prise || 0;
    const fuels = form.fuels?.prise || 0;

    const sum = bonus + mezanica + fuels;
    updateData('prise', sum)
  }, [form.bonus, form.mezanica, form.fuels])


  if (send)
    return (
      <div className={style.container}>
        <div className={style.box}>
          <h1>Менеджер набер вас як звільниться</h1>
          <p>Вдалого вам дня 😊</p>
        </div>
      </div>
    );

  return (
    <div className={style.container}>
      <div className={style.box}>
        <p className={style.title}>Менеджер набер вас як тільки звільниться </p>
        <Form updateData={updateData} form={form} />
        <div className={style.priseBlock}>
          Вартість: <span>{form.prise} грн.</span>
          {form.bonus ? <span className={style.oldPrise}>{form.prise + 1200} грн.</span> : ''}
        </div>
      </div>
    </div>
  );
};

export default Phone;
