import React, { useCallback } from 'react';

import { useModal } from '../../../app/context/modal';

import IconModal from './IconModal';

import style from './style.module.scss';

const Modal = () => {
  const modal = useModal();

  const closeMenu = useCallback(() => {
    modal?.unSetModal();
  }, [modal?.modals]);

  const ModalsContent = useCallback(() => {
    return modal?.modals.map(el => {
      switch (el.type) {
        case 'icon': {
          return <IconModal key={el.id} {...el} />;
        }
        default: {
          return <div>standard</div>;
        }
      }
    });
  }, [modal?.modals]);

  return (
    <div
      className={[style.container, modal?.modals.length && style.active].join(
        ' ',
      )}
      onClick={closeMenu}
    >
      {ModalsContent()}
    </div>
  );
};

export default Modal;
