import { FC, ReactNode, createContext, useContext, useReducer } from 'react';
import { StatusReq } from '../../type';
import { initState } from './type';

const MenuStateContext = createContext<initState>({
  status: StatusReq.idle,
  open: false,
  error: null,
});
const MenuDispatchContext = createContext<any>(null);

const reducer = (currentState: any, newState: any) => ({
  ...currentState,
  ...newState,
});

const useMenuState = () => {
  const context = useContext(MenuStateContext);
  if (!context) throw new Error('useMenuState must be in MenuProvider');

  return context;
};

const useMenuDispatch = () => {
  const context = useContext(MenuDispatchContext);
  if (!context) throw new Error('useMenuDispatch must be in MenuProvider');

  return context;
};

const initialState: initState = {
  status: StatusReq.idle,
  open: false,
  error: null,
};

type Props = {
  children: ReactNode;
};

const MenuProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <MenuStateContext.Provider value={state}>
      <MenuDispatchContext.Provider value={dispatch}>
        {children}
      </MenuDispatchContext.Provider>
    </MenuStateContext.Provider>
  );
};

const useVisible = (dispatch: any, open: boolean) => {
  dispatch({ open });
};

export { MenuProvider, useMenuState, useMenuDispatch, useVisible };
