import { useState } from 'react';
import {
  LanguageApi,
  useLanguageDispatch,
  useLanguageState,
} from '../context/language';
import { LanguageApiEnum } from '../context/language/type/type';

export default () => {
  const initState = useLanguageState();
  const dispatch = useLanguageDispatch();
  const [spreadDictionary, setSpreadDictionary] = useState<any>(null);

  const languageApiSwitch = async (url: string, type: LanguageApiEnum) => {
    await setWords(`${type}.${url}`, initState.dictionary, () =>
      LanguageApi(dispatch, `${initState.language}.${type}.${url}`, initState),
    );
  };

  const DictionaryAll = (arr: Promise<void>[]) => {
    Promise.all(arr).then(res => res);
  };

  const getWords = (stringKeys: string, dict: object): object | undefined => {
    const splitKeys = stringKeys.split('.');
    const words = splitKeys.reduce(
      (acc: any, curr: string) => (!acc ? false : acc[curr]),
      dict,
    );

    return words && words;
  };

  const setWords = async (
    str: string,
    dict: object,
    toApiLanguage: () => Promise<object>,
  ): Promise<void> => {
    const words = getWords(str, dict);
    if (words) {
      setSpreadDictionary((prev: object) => ({
        ...prev,
        ...words,
      }));
      return;
    }

    const obj: object = await toApiLanguage();
    setWords(str, obj, toApiLanguage);
  };

  return {
    LanguageApi: languageApiSwitch,
    dictionaryAll: DictionaryAll,
    dictionary: spreadDictionary,
  };
};
