import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import style from './style.module.scss';

import { InputText } from '../../../components/ux/Inputs';

import { StatusSteps } from '../../type/form';
import { SendFormType } from '../../../components/module/Quiz';
import { useForm } from '../../context/form';

type Props = {
  sendForm: SendFormType;
  setStatusSteps: Dispatch<SetStateAction<StatusSteps>>;
};

const Name: FC<Props> = ({ sendForm, setStatusSteps }) => {
  const { form, updateData } = useForm();

  const [errorForms, setErrorForms] = useState<boolean>(false);

  const checkFrom = (): boolean => {
    let isNext = true;
    if (!form.name) {
      setErrorForms(() => !!!form.name);
      isNext = false;
    }
    setStatusSteps((prev: StatusSteps) => ({
      ...prev,
      name: isNext,
    }));
    return isNext;
  };

  useEffect(() => {
    sendForm(() => checkFrom);
  }, [form.name, errorForms]);

  return (
    <div className={style.container}>
      <div className={style.box}>
        <p className={style.title}>Давай знайомитись, як тебе звати?</p>
        <InputText
          value={form.name}
          setValue={(value: ChangeEvent<HTMLInputElement>) =>
            updateData('name', value.target.value)
          }
          placeholder="Тебе звати..."
          className={style.inputBox}
          necessary
          errorMessage={!form.name ? 'Обовʼязково вкажіть' : ''}
          error={errorForms}
        />
      </div>
    </div>
  );
};

export default Name;
