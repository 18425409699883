export enum StatusReq {
  idle = 'idle',
  pending = 'pending',
  resolved = 'resolved',
  rejected = 'rejected',
}

export enum Language {
  'uk' = 'uk',
  'en' = 'en',
  standard = 'uk',
}
