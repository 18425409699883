import React, { ChangeEvent, FC, useMemo, useState } from 'react';

import style from './style.module.scss';
import { useModal } from '../../../app/context/modal';
import {
  InputPhone,
  InputRadio,
  InputSelect,
} from '../../../components/ux/Inputs';
import { TypeForm, updateDataType } from '../../type/form';

export type PropsInfo = {
  lastNameValue: any;
};

type RadioType = {
  select: SelectTypeArr | null;
  arr: SelectTypeArr[];
  upData: updateDataType;
  form: TypeForm;
  update: string;
};

type SelectType = {
  value: string;
  title: string;
  describe: string;
  arr: SelectTypeArr[];
  select: SelectTypeArr | null;
  update: string;
  upData: updateDataType;
};

type SelectTypeArr = { value: string; prise: number };

type OpenModalType = (
  title: string,
  description: string,
  arr: SelectTypeArr[],
  select: SelectTypeArr | null,
  update: string,
  upData: updateDataType,
) => void;

type FormType = {
  updateData: updateDataType;
  form: TypeForm;
};

const RadiosModal: FC<RadioType> = ({ arr, select, upData, update }) => {
  const [isSelect, setIsSelect] = useState(select);

  const Change = (item: SelectTypeArr) => {
    upData(update, item);
    setIsSelect(item);
  };

  const areBusiness = useMemo(
    () =>
      arr.map(item => (
        <InputRadio
          key={item.value}
          value={item.value}
          name="value"
          title={item.value}
          setValue={() => Change(item)}
          checked={item.value === isSelect?.value}
        />
      )),
    [isSelect, update, select, upData],
  );

  return <div className={style.boxSelect}>{areBusiness}</div>;
};

const colors: SelectTypeArr[] = [
  { value: 'Білий', prise: 0 },
  { value: 'Бетон', prise: 0 },
  { value: 'Мармур', prise: 0 },
];
const fuels: SelectTypeArr[] = [
  { value: 'Вистачить що є в комплекті, 300мл', prise: 0 },
  { value: '+300мл. 60грн', prise: 60 },
  { value: '+1л. 180грн', prise: 180 },
  { value: '+5л. 650грн', prise: 650 },
  { value: 'Ще не знаю', prise: 0 },
];
const mezanica: SelectTypeArr[] = [
  { value: 'Так', prise: 600 },
  { value: 'Ні', prise: 0 },
  { value: 'Ще не знаю', prise: 0 },
];

const Form: FC<FormType> = ({ updateData, form }) => {
  const modal = useModal();

  const openModal: OpenModalType = (
    title,
    description,
    arr,
    select,
    update,
    upData,
  ) => {
    modal?.addModal({
      modals: {
        title,
        description,
        size: 'small',
      },
      Content: RadiosModal,
      props: { arr, select, upData, form, update },
      type: 'icon',
    });
  };

  const Select: FC<SelectType> = ({
    value,
    title,
    describe,
    arr,
    select,
    update,
    upData,
  }) => (
    <InputSelect
      value={value}
      setValue={() => openModal(title, describe, arr, select, update, upData)}
      title={title}
    />
  );

  return (
    <div className={style.box}>
      {/* <Select
        value={form?.color?.value || 'Обріть колір'}
        title="Колір"
        describe="Оберіть 1 з 4 кольорів біокаміна"
        arr={colors}
        select={form?.color}
        update="color"
        upData={updateData}
      />

      <Select
        value={form?.fuels?.value || 'Обріть об`єм'}
        title="Біопаливо"
        describe="100мл = 50-60хв горіння"
        arr={fuels}
        select={form?.fuels}
        update="fuels"
        upData={updateData}
      />

      <Select
        value={form?.mezanica?.value || 'Обріть підставку'}
        title="Менажниця"
        describe="Менажниця (500грн.)"
        arr={mezanica}
        select={form?.mezanica}
        update="mezanica"
        upData={updateData}
      /> */}

      <InputPhone
        value={(form.phone && String(form.phone)) || undefined}
        setValue={(value: string) => updateData('phone', value)}
        placeholder={'099...'}
        title={'Номер телефону'}
      />
    </div>
  );
};

export default Form;
