import React, {
  FC,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

import Content from './components/Content';

import DictionaryProvider, { useDictionary } from './context/Dictionary';

import Footer from './components/Footer';
import Header from './components/Header';

import style from './style.module.scss';
import Loader from '../Loader';

export type SendFormType = (sendForm: () => () => boolean) => boolean;

type Props = {
  title: string;
  contentSteps: {
    name: string;
    step: number;
    status: boolean;
    content: {
      title?: string;
      descriptions?: string;
      Form: (
        sendForm: SendFormType,
        setStep: Dispatch<SetStateAction<number>>,
      ) => ReactNode;
    };
  }[];
};

const StepsForm: FC<Props> = ({ contentSteps, title }) => {
  const [step, setStep] = useState(1);
  const [form, setForm] = useState<(() => boolean) | null>(null);

  const dictionary = useDictionary();

  if (!dictionary) return <Loader />;

  return (
    <div className={style.container}>
      <Header
        contentSteps={contentSteps}
        title={title}
        step={step}
        setStep={setStep}
      />
      <Content
        contentSteps={contentSteps}
        step={step}
        sendForm={setForm}
        setStep={setStep}
      />
      <Footer
        contentSteps={contentSteps}
        step={step}
        setStep={setStep}
        sendForm={form}
        dictionary={dictionary}
      />
    </div>
  );
};

export default (props: Props) => (
  <DictionaryProvider>
    <StepsForm {...props} />
  </DictionaryProvider>
);
