import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import useAlert from '../../../../../app/hooks/useAlert';

import Button from '../../../../ux/Buttons/Standard';

import style from './style.module.scss';
import { AlertType } from '../../../../../app/context/alert/type';

type Props = {
  contentSteps: {
    name: string;
    step: number;
    status: boolean;
  }[];
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  sendForm: (() => boolean) | null;
  dictionary: {
    back: string;
    next: string;
    send: string;
    errorNext: string;
  };
};

const StepsForm: FC<Props> = ({
  contentSteps,
  step,
  setStep,
  sendForm,
  dictionary,
}) => {
  const alert = useAlert();
  const [viewBtn, setViewBtn] = useState(true);

  const ChangeSteps = (sum: boolean | 'send') => {
    if (!sendForm) {
      throw new Error('Empty StepsForm');
    }

    const nextStep = sendForm();

    if (sum === 'send' && nextStep) {
      setViewBtn(false);
      return;
    }

    if (!nextStep && sum) {
      return alert?.addAlert({
        message: String(dictionary.errorNext),
        type: AlertType.error,
      });
    }
    setStep((prev: number) => {
      if (sum && prev + 1 <= contentSteps.length) {
        return prev + 1;
      } else if (!sum && prev - 1 !== 0) {
        return prev - 1;
      }

      return prev;
    });
  };

  const buttons = useMemo(() => {
    if (step === 1) {
      return (
        <Button
          text={dictionary.next}
          active={() => ChangeSteps(true)}
          size="large"
          quiz
          className={style.btnFill}
        />
      );
    } else if (step === contentSteps.length) {
      return (
        <>
          <Button
            transparent
            quiz
            text={dictionary.back}
            active={() => ChangeSteps(false)}
            size="large"
          />
          <Button
            text={dictionary.send}
            active={() => ChangeSteps('send')}
            size="large"
            quiz
            className={style.btnFill}
          />
        </>
      );
    } else {
      return (
        <>
          <Button
            text={dictionary.back}
            active={() => ChangeSteps(false)}
            size="large"
            transparent
            quiz
          />
          <Button
            text={dictionary.next}
            active={() => ChangeSteps(true)}
            size="large"
            quiz
            className={style.btnFill}
          />
        </>
      );
    }
  }, [step, contentSteps, sendForm]);

  return (
    <div className={style.footer}>
      <div className={style.boxButton}>{viewBtn && buttons}</div>
    </div>
  );
};

export default StepsForm;
