import add from '../../../assets/img/menu/add.png';
import store from '../../../assets/img/menu/store.png';
import doc from '../../../assets/img/menu/doc.png';
import pay from '../../../assets/img/menu/pay.png';
import box from '../../../assets/img/menu/box.png';
import chart from '../../../assets/img/menu/chart.png';
import settings from '../../../assets/img/menu/settings.jpg';

import Menu from '../language/type/module/menu';

const MENU = (menu: Menu) => [
  {
    name: menu.profile.name,
    menu: [
      {
        name: menu.profile.business,
        img: add,
        path: '',
      },
      {
        name: menu.profile.workers,
        img: store,
        path: 'worker',
      },
      {
        name: menu.profile.addBusiness,
        img: box,
        path: 'business',
      },
    ],
  },
  {
    name: menu.setting.name,
    menu: [
      {
        name: menu.setting.profile,
        img: settings,
        path: 'settings/profile',
      },
      {
        name: menu.setting.security,
        img: doc,
        path: 'settings/security',
      },
      {
        name: menu.setting.payment,
        img: pay,
        path: 'settings/payment',
      },
    ],
  },
];

export default MENU;
