import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {
  em: 'doctype.site@gmail.com',
  fn: 'Andrii',
  ln: 'Melnik',
  ct: 'Lutsk',
  country: 'ua',
  ph: '',
  db: '',
  ge: 'f',
  st: '',
  zp: '',
};
const options = {
  autoConfig: true,
  debug: false,
};

const PixelID = '1113099773396452';

class PixelFB {
  pageView() {
    return ReactPixel.pageView();
  }

  init() {
    return ReactPixel.init(PixelID, advancedMatching, options);
  }
}

export default new PixelFB();
