import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
  FC,
} from 'react';
import { Modal, ContextType } from './type';

const ModalContext = React.createContext<ContextType | null>(null);

type Props = {
  children: ReactNode;
};

const ModalProvider: FC<Props> = ({ children }) => {
  const [modals, setModals] = useState<Modal[]>([]);

  const addModal = useCallback((modal: Modal) => {
    setModals(prev => [
      ...prev,
      {
        ...modal,
        id: prev.length + 1,
      },
    ]);
  }, []);

  const unSetModal = useCallback(() => {
    setModals(prev => prev.filter(e => e.id !== modals.length));
  }, [modals]);

  const contextValue = useMemo(
    () => ({
      unSetModal,
      addModal,
      modals,
    }),
    [unSetModal, addModal, modals.length],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined)
    throw new Error('useModal must be used within a ModalProvider');

  return context;
};

export { ModalProvider, useModal };
