import React from 'react';
import style from './style.module.scss';
import loader from '../../../assets/gif/loader.gif';

const Loader = () => (
  <div className={style.container}>
    <img src={loader} alt="loader" />
  </div>
);

export default Loader;
