import { useEffect, createContext, useContext, ReactNode, FC } from 'react';

import useLanguageApi from '../../../../app/hooks/useLanguageApi';

import { LanguageApiEnum } from '../../../../app/context/language/type/type';

import UpdateStandard from '../../../../app/context/language/type/module/updateStandard';

type DictionaryType = UpdateStandard | null;

const DictionaryContext = createContext<DictionaryType>(null);

type Props = {
  children: ReactNode;
};

const DictionaryProvider: FC<Props> = ({ children }) => {
  const { LanguageApi, dictionaryAll, dictionary } = useLanguageApi();
  useEffect(() => {
    dictionaryAll([LanguageApi('form_steps', LanguageApiEnum.component)]);
  }, []);

  return (
    <DictionaryContext.Provider value={dictionary}>
      {children}
    </DictionaryContext.Provider>
  );
};

export const useDictionary = () => {
  const context = useContext(DictionaryContext);

  if (context === undefined)
    throw new Error('useDictionary must be used within a DictionaryProvider');

  return context;
};

export default DictionaryProvider;
