export type AppContextAlert = {
  unSetAlert: (id: number) => void;
  addAlert: (alert: AlertDate) => void;
  alerts: AlertDate[];
};

export enum AlertType {
  done = 'done',
  info = 'info',
  error = 'error',
  app = 'app',
}

export type AlertDate = {
  id?: number;
  title?: string;
  img?: string;
  type: AlertType;
  message: string;
};
