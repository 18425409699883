import axios from 'axios';
import { getCookie } from './cookies';

const API = process.env.REACT_APP_URL_API;
const DOMAIN = process.env.REACT_APP_DOMAIN;
const LANGUAGE_URL = process.env.REACT_APP_LANGUAGE_URL;

export const Redirect = (href = DOMAIN || '/') => {
  window.location.href = href;
};

export const RedirectSubDomain = (subdomain = '') => {
  return `application/${subdomain}`;
};

export const ServiceLanguage = axios.create({
  baseURL: LANGUAGE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

ServiceLanguage.interceptors.response.use(
  response => response.data,
  error => error,
);

const Service = axios.create({
  baseURL: API,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

Service.interceptors.request.use(
  config => {
    const accessToken = getCookie('token');

    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    Promise.reject(error.response || error.message);
  },
);

Service.interceptors.response.use(
  response => response,

  async function (error) {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refreshToken');

    if (
      refreshToken &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      return axios
        .post(`${API}/authentication/refresh`, {
          refreshToken: refreshToken,
        })
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('refreshToken', res.data.refreshToken);

            originalRequest.headers[
              'Authorization'
            ] = `Bearer ${res.data.accessToken}`;

            return axios(originalRequest);
          }
        })
        .catch(() => {
          localStorage.clear();
          // location.reload();
        });
    } else {
      return Promise.reject(error);
    }
  },
);

export default Service;
