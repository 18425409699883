import React, { ChangeEvent, useCallback, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useMenuDispatch,
  useMenuState,
  useVisible,
} from '../../../app/context/menu';
import DictionaryProvider, { useDictionary } from './context/Dictionary';
import MENU from '../../../app/context/menu/menu';

import { InputSearch } from '../../ux/Inputs';
import Loader from '../Loader';

import style from './style.module.scss';

const Menu = () => {
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();
  const { open } = useMenuState();
  const menuDispatch = useMenuDispatch();

  const dictionary = useDictionary();

  const closeMenu = () => {
    useVisible(menuDispatch, false);
  };

  const preventCloseMenu = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      e.stopPropagation();
    },
    [],
  );

  const redirect = useCallback((path = '/') => {
    navigate(path);
    closeMenu();
  }, []);

  if (!dictionary) return <Loader />;


  const menu = MENU(dictionary);

  const contextMenu = () => {
    const filterMenu = menu.map(
      el => {
        const filterLi = el.menu.filter(li =>
          li.name.toLowerCase().includes(search.toLowerCase()),
        );

        return (
          filterLi.length && {
            name: el.name,
            menu: filterLi,
          }
        );
      },
      [menu],
    );

    return filterMenu.map(el => {
      if (el) {
        return (
          <div className={style.containerSliceMenu} key={el.name}>
            <div className={style.boxTitle}>
              <hr />
              <h3>{el.name}</h3>
              <hr />
            </div>
            <div className={style.boxMenu}>
              {el.menu.map(li => {
                return (
                  <div
                    className={style.boxItemMenu}
                    key={li.name}
                    onClick={preventCloseMenu}
                  >
                    <div
                      className={style.itemMenu}
                      onClick={() => redirect(li.path)}
                    >
                      <div className={style.boxImg}>
                        <img src={li.img} alt={li.name + ' menu item'} />
                      </div>

                      <p>{li.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div
      className={
        !open ? style.container : [style.container, style.active].join(' ')
      }
      onClick={closeMenu}
    >
      <div className={style.headerBox}>
        <div className={style.header}>
          <div className={style.boxSearch} onClick={preventCloseMenu}>
            <InputSearch
              value={search}
              setValue={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              placeholder={dictionary.search}
            />
          </div>
        </div>
      </div>
      <div className={style.content}>{contextMenu()}</div>
    </div>
  );
};

export default () => (
  <DictionaryProvider>
    <Menu />
  </DictionaryProvider>
);
