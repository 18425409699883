import React, { useCallback } from 'react';

import { AlertType } from '../../../app/context/alert/type';

import useAlert from '../../../app/hooks/useAlert';

import style from './style.module.scss';

import { ReactComponent as Cancel } from '../../../assets/svg/else/cancel.svg';
import { ReactComponent as Done } from '../../../assets/svg/status/done.svg';
import { ReactComponent as Info } from '../../../assets/svg/status/info.svg';
import { ReactComponent as Error } from '../../../assets/svg/status/error.svg';

import defaultImg from '../../../assets/img/alert.png';

const Alerts = () => {
  const alert = useAlert();

  const deleteAlert = useCallback((id: number) => {
    alert?.unSetAlert(id);
  }, []);

  const statusIcon = useCallback(
    (type: AlertType, img: string | undefined = defaultImg) => {
      switch (type) {
        case AlertType.done:
          return <Done />;

        case AlertType.info:
          return <Info className={style.iconInfo} />;

        case AlertType.error:
          return <Error />;
        case AlertType.app:
          return <img src={img} alt="alert icon" />;
      }
    },
    [],
  );

  const allAlerts = useCallback(() => {
    return alert?.alerts.map(el => {
      return (
        <div key={el.id} className={[style.box, style[el.type]].join(' ')}>
          <div className={style.iconContainer}>
            <div className={style.iconBox}>{statusIcon(el.type, el?.img)}</div>
          </div>
          <div className={style.boxMessage}>
            <h5>{el.title}</h5>
            <p>{el.message}</p>
          </div>
          <div className={style.boxClose}>
            <button onClick={() => deleteAlert((el.id = 0))}>
              <Cancel />
            </button>
          </div>
        </div>
      );
    });
  }, [alert?.alerts])();

  if (!alert?.alerts) return <></>;

  return <div className={style.alertContainer}>{allAlerts}</div>;
};

export default Alerts;
