import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import style from './style.module.scss';

import { StatusSteps } from '../../type/form';
import { SendFormType } from '../../../components/module/Quiz';
import { useForm } from '../../context/form';

const bonuses = [
  {
    id: 1,
    name: '🪵 Межинаця',
    describe: 'Супер, +межаниця за 500грн.',
  },
  {
    id: 2,
    name: '🏷️ Знижка -20%',
    describe: 'Приєна знижка,  твоя ціна - 2400грн.',
  },
  {
    id: 3,
    name: '⛽️ 5 Літрів Біопалива',
    describe: 'Вітаю, + 550 - 600 хв горіня, і економія 650грн.',
  },
  {
    id: 4,
    name: '😕 Нічого',
    describe: 'Уви бонусу немає, твоя ціна стандартна - 3000грн.',
  },
];

type Props = {
  sendForm: SendFormType;
  setStatusSteps: Dispatch<SetStateAction<StatusSteps>>;
};

const Bonus: FC<Props> = ({ sendForm, setStatusSteps }) => {
  const { form, updateData } = useForm();

  const [errorForms, setErrorForms] = useState<boolean>(false);

  const checkFrom = (): boolean => {
    let isNext = true;
    if (!form.bonus) {
      setErrorForms(() => !!!form.bonus);
      isNext = false;
    }

    setStatusSteps((prev: StatusSteps) => ({
      ...prev,
      bonus: isNext,
    }));
    return isNext;
  };

  useEffect(() => {
    sendForm(() => checkFrom);
  }, [form.bonus, errorForms]);

  const allCards = useCallback(() => {
    if (form.bonus) {
      return bonuses.map(el => (
        <div className={[style.card, style.active].join(' ')} key={el.id}>
          <div className={style.front}>Натисни тут 2</div>
          <div
            className={[style.back, form.bonus == el.id && style.bonus].join(
              ' ',
            )}
          >
            <p> {form.bonus == el.id ? '🔥 Знижка -40%' : el.name}</p>
            <span>
              {form.bonus == el.id
                ? 'Мої вітання, тобі повезло вибрати максимальну знижку. Стандартна ціна: 3000грн, твоя ціна - 1800грн'
                : el.describe}
            </span>
          </div>
        </div>
      ));
    }

    return bonuses.map(el => (
      <div
        className={style.card}
        key={el.id}
        onClick={() => updateData('bonus', el.id)}
      >
        <div className={style.front}>Натисни тут 🎁 </div>
        <div className={[style.back, style.bonus].join(' ')}></div>
      </div>
    ));
  }, [form.bonus]);

  return <div className={style.container}>{allCards()}</div>;
};

export default Bonus;
