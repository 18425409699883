import React, { Dispatch, FC, ReactNode, SetStateAction, useMemo } from 'react';

import style from './style.module.scss';

import { SendFormType } from '../../index';

type Props = {
  contentSteps: {
    content: {
      title?: string;
      descriptions?: string;
      Form: (
        sendForm: SendFormType,
        setStep: Dispatch<SetStateAction<number>>,
      ) => ReactNode;
    };
    step: number;
  }[];
  step: number;

  setStep: Dispatch<SetStateAction<number>>;
  sendForm: any;
};

const Content: FC<Props> = ({ contentSteps, step, sendForm, setStep }) => {
  const ListContent = useMemo(() => {
    return contentSteps.map(el => {
      if (el.step === step) {
        return (
          <React.Fragment key={el.step}>
            <div className={style.header}>
              {el.content.title && (
                <h3 className={style.title}>{el.content.title}</h3>
              )}
              {el.content.descriptions && (
                <p className={style.descriptions}>{el.content.descriptions}</p>
              )}
            </div>

            <div>{el.content.Form(sendForm, setStep)}</div>
          </React.Fragment>
        );
      }
    });
  }, [step]);
  return <div className={style.container}>{ListContent}</div>;
};

export default Content;
