import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import style from './style.module.scss';
import Check from '../../../components/ux/Inputs/Check';

import img_concrete_1 from '../../img/fireplace/concrete_1.jpg';
import img_concrete_4 from '../../img/fireplace/concrete_4.jpg';
import img_concrete_5 from '../../img/fireplace/concrete_5.jpg';
import img_concrete_m_1 from '../../img/fireplace/concrete_m_1.jpg';
import img_concrete_m_4 from '../../img/fireplace/concrete_m_4.jpg';
import img_marmur_1 from '../../img/fireplace/marmur_1.jpg';
import img_marmur_concrete_1 from '../../img/fireplace/marmur_concrete_1.jpg';
import img_marmur_concrete_m_1 from '../../img/fireplace/marmur_concrete_m_1.jpg';
import img_marmur_m_1 from '../../img/fireplace/marmur_m_1.jpg';
import img_white_1 from '../../img/fireplace/white_1.jpg';
import img_white_2 from '../../img/fireplace/white_2.jpg';
import img_white_m_1 from '../../img/fireplace/white_m_1.jpg';

import { StatusSteps } from '../../type/form';
import { useModal } from '../../../app/context/modal';

type ColorArrType = {
  id: number;
  value: string;
  name: string;
  checked?: boolean | undefined;
  img: string;
};

type Props = {
  setStatusSteps: Dispatch<SetStateAction<StatusSteps>>;
};

const ColorArr: ColorArrType[] = [
  {
    id: 8,
    value: 'burgundy',
    name: 'Мармур Бетон + Межаниця',
    checked: false,
    img: img_marmur_concrete_m_1,
  },
  {
    id: 9,
    value: 'tiffany',
    name: 'Мармур + Межаниця',
    checked: false,
    img: img_marmur_m_1,
  },
  {
    id: 5,
    value: 'dark-powder',
    name: 'Бетон + Межаниця',
    checked: false,
    img: img_concrete_m_4,
  },
  {
    id: 11,
    value: 'emerald',
    name: 'Білий',
    checked: false,
    img: img_white_2,
  },
  {
    id: 1,
    value: 'sea-wave',
    name: 'Бетон',
    checked: false,
    img: img_concrete_1,
  },
  {
    id: 2,
    value: 'red',
    name: 'Бетон',
    checked: false,
    img: img_concrete_4,
  },
  {
    id: 6,
    value: 'electrician',
    name: 'Мармур',
    checked: false,
    img: img_marmur_1,
  },
  {
    id: 10,
    value: 'marsala',
    name: 'Білий',
    checked: false,
    img: img_white_1,
  },
  {
    id: 7,
    value: 'dark',
    name: 'Бетон Мармур',
    checked: false,
    img: img_marmur_concrete_1,
  },
  {
    id: 12,
    value: 'cappuccino',
    name: 'Білий + Межаниця',
    checked: false,
    img: img_white_m_1,
  },
  {
    id: 4,
    value: 'powder',
    name: 'Бетон + Межаниця',
    checked: false,
    img: img_concrete_m_1,
  },
  {
    id: 3,
    value: 'blue',
    name: 'Бетон',
    checked: false,
    img: img_concrete_5,
  },
];

const Color: FC<Props> = ({ setStatusSteps }) => {
  const modal = useModal();

  const openModal = (img: string, title: string) => {
    modal?.addModal({
      modals: {
        title: 'Біокамін',
        description: title,
        size: 'medium',
      },
      Content: ModalImg,
      props: { img },
      type: 'icon',
    });
  };

  useEffect(() => {
    setStatusSteps((prev: StatusSteps) => ({
      ...prev,
      color: true,
    }));
  }, []);

  const areColor = useCallback(() => {
    return ColorArr.map((item: ColorArrType) => {
      return (
        <div
          className={style.check}
          key={item.id}
          onClick={() => openModal(item.img, item.name)}
        >
          <div className={style.imgBox}>
            <img src={item.img} alt="" />
          </div>

          <Check
            value={item.value}
            name="color"
            title={item.name}
            // checked={item.id == form.color}
            className={style.checkBox}
          />
        </div>
      );
    });
  }, []);

  return <div className={style.container}>{areColor()}</div>;
};

export default Color;

const ModalImg = ({ img }: { img: string }) => {
  return (
    <div className={style.imgModal}>
      <img src={img} alt="previes photo" />
    </div>
  );
};
