import React, { useCallback, useEffect, useState } from 'react';

import style from './style.module.scss';

import Quiz, { SendFormType } from '../components/module/Quiz';
import { StatusSteps } from './type/form';

import Name from './modules/Name';
import Why from './modules/Why';
import Color from './modules/Color';
import Bonus from './modules/Bonus';
import Firepalce from './modules/Firepalce';
import Phone from './modules/Phone';
import { FormProvider } from './context/form';

import PixelFB from './pixel/fb';

const QuizPage = () => {
  const [statusSteps, setStatusSteps] = useState<StatusSteps>({
    name: false,
    why: false,
    color: false,
    bonus: false,
    phone: false,
    fireplase: false,
  });

  useEffect(() => {
    PixelFB.init();
    PixelFB.pageView();
  }, []);

  const contentSteps = useCallback(
    () => [
      {
        name: 'Привіт',
        step: 1,
        status: statusSteps.name,
        content: {
          title: 'Привіт, мене звати Андрій, ми виготовляємо біокаміни😎',
          descriptions: 'Радий тебе бачити, розповім тобі більше про біокаміни',
          Form: (sendForm: SendFormType) => (
            <Name sendForm={sendForm} setStatusSteps={setStatusSteps} />
          ),
        },
      },
      {
        name: 'Чому?',
        step: 2,
        status: statusSteps.why,
        content: {
          title: 'Чому тобі потрібно біокамін?',
          Form: (sendForm: any) => (
            <Why sendForm={sendForm} setStatusSteps={setStatusSteps} />
          ),
        },
      },
      {
        name: 'Біокамін',
        step: 3,
        status: statusSteps.fireplase,
        content: {
          title: '2 слова про Біокамін',
          descriptions: '90% не знають що це',
          Form: (sendForm: any) => (
            <Firepalce setStatusSteps={setStatusSteps} />
          ),
        },
      },
      {
        name: 'Колір',
        step: 4,
        status: statusSteps.color,
        content: {
          title: 'Який подобається найбільше?',
          descriptions:
            'Можна спробувати щось новеньке, 4 унікальних дизайна 🎨',
          Form: (sendForm: SendFormType) => (
            <Color setStatusSteps={setStatusSteps} />
          ),
        },
      },
      {
        name: 'Бонус',
        step: 5,
        status: statusSteps.bonus,
        content: {
          title: 'Якщо хочеш бонуc - обирай будь яку картку',
          Form: (sendForm: SendFormType) => (
            <Bonus sendForm={sendForm} setStatusSteps={setStatusSteps} />
          ),
        },
      },

      {
        name: 'Замовити',
        step: 6,
        status: statusSteps.phone,
        content: {
          title: 'Тут можна оформити замовлення',
          descriptions: statusSteps.bonus
            ? '🎁 Твій бонус це -40% на біокамін. Вартість 1800грн.'
            : '',
          Form: (sendForm: SendFormType) => (
            <Phone sendForm={sendForm} setStatusSteps={setStatusSteps} />
          ),
        },
      },
    ],
    [statusSteps],
  );

  return (
    <div className={style.container}>
      <Quiz title="БІОКАМІН 🔥" contentSteps={contentSteps()} />
    </div>
  );
};

export default () => (
  <FormProvider>
    <QuizPage />
  </FormProvider>
);
