import { Dispatch } from 'react';
import LanguageApi from './api';
import { dictionary, initState } from './type';
import { Language } from '../../type';

class Reducer {
  async setSliceDictionary(
    dispatch: Dispatch<initState>,
    str: string,
    initState: initState,
  ) {
    const { url, keys } = this._parse(str);
    const dictionarySlice: object = await LanguageApi(url);
    const splitKeys = keys.slice(1, keys.length);

    const newDictionary = this._setWordsToDictionary(
      splitKeys,
      initState.dictionary,
      dictionarySlice,
    );

    dispatch({
      ...initState,
      dictionary: {
        ...initState.dictionary,
        ...newDictionary,
      },
    });

    return newDictionary;
  }

  setLanguage(
    dispatch: Dispatch<initState>,
    lang: Language,
    initState: initState,
  ) {
    // window.location.reload();
    dispatch({
      ...initState,
      language: lang,
      dictionary: {},
    });
  }
  private _setWordsToDictionary(
    splitKeys: string | string[],
    dictionary: dictionary,
    dictionarySlice: object,
    index = 0,
  ): object {
    if (splitKeys.length === index) {
      return dictionarySlice;
    }

    // @ts-ignore
    const dictionaryIs = dictionary[splitKeys[index]] || {};

    return {
      [splitKeys[index]]: {
        ...dictionaryIs,
        ...this._setWordsToDictionary(
          splitKeys,
          dictionaryIs,
          dictionarySlice,
          index + 1,
        ),
      },
    };
  }

  private _parse(str: string) {
    const keys = str.split('.');
    const url = str.replace(/\./g, '/') + '.json';
    return { keys, url };
  }
}
export default new Reducer();
