import React, {
  useCallback,
  useMemo,
  useState,
  createContext,
  ReactNode,
  FC,
} from 'react';
import { AlertDate, AppContextAlert } from './type';

export const AlertContext = createContext<AppContextAlert | null>(null);

type Props = {
  children: ReactNode;
};

const AlertProvider: FC<Props> = ({ children }) => {
  const [alerts, setAlert] = useState<AlertDate[]>([]);
  const [idAlert, setIdAlert] = useState<number>(1);

  const addAlert = useCallback(
    (alert: AlertDate) => {
      setIdAlert(prev => ++prev);
      setAlert(prev => [...prev, { ...alert, id: idAlert }]);
    },
    [idAlert, alerts],
  );

  const unSetAlert = useCallback((id: number) => {
    setAlert(prev => prev.filter(e => e.id !== id));
  }, []);

  const contextValue = useMemo(
    () => ({
      unSetAlert,
      addAlert,
      alerts,
    }),
    [unSetAlert, alerts, idAlert],
  );

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
