import {
  useContext,
  useReducer,
  createContext,
  Dispatch,
  ReactNode,
  FC,
} from 'react';

import { initState } from './type';

import Reducer from './reducer';
import { Language, StatusReq } from '../../type';

const initialState: initState = {
  language: Language['standard'],
  dictionary: {},
  status: StatusReq.idle,
  type: null,
};

const LanguageStateContext = createContext<initState>(initialState);
const LanguageDispatchContext = createContext<Dispatch<initState> | null>(null);

const reducer = (currentState: initState, newState: initState) => ({
  ...currentState,
  ...newState,
});

const useLanguageState = () => {
  const context = useContext(LanguageStateContext);
  if (!context)
    throw new Error('useLanguageState must be used in AuthProvider');

  return context;
};

const useLanguageDispatch = () => {
  const context = useContext(LanguageDispatchContext);
  if (!context)
    throw new Error('useLanguageDispatch must be used in AuthProvider');

  return context;
};
type Props = {
  children: ReactNode;
};

const LanguageProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LanguageStateContext.Provider value={state}>
      <LanguageDispatchContext.Provider value={dispatch}>
        {children}
      </LanguageDispatchContext.Provider>
    </LanguageStateContext.Provider>
  );
};

const LanguageApi = async (
  dispatch: Dispatch<initState>,
  url: string,
  initState: initState,
): Promise<object> =>
  await Reducer.setSliceDictionary(dispatch, url, initState);

const SetLanguage = (
  dispatch: Dispatch<initState>,
  lang: Language,
  initState: initState,
) => Reducer.setLanguage(dispatch, lang, initState);

export {
  LanguageProvider,
  useLanguageState,
  useLanguageDispatch,
  LanguageApi,
  SetLanguage,
};
