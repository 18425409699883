import React from 'react';

import AppQuiz from './quiz';

import style from './style.module.scss';

const App = () => {
  return (
    // <div className={!open ? style.app : [style.app, style.modal].join(' ')}>
    <div>
      <AppQuiz />
    </div>
  );
};

export default App;
