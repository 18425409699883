import React, { ChangeEvent, FC, useState } from 'react';

import { DefaultPropsInputSearch } from '../type';

import style from './style.module.scss';
import styles from '../styles.module.scss';

import { ReactComponent as Search_icon } from '../../../../assets/svg/else/search.svg';

const Search: FC<DefaultPropsInputSearch> = ({
  value,
  setValue,
  placeholder,
  disabled,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <div className={styles.container}>
      <label
        className={focus ? [styles.active, style.box].join(' ') : style.box}
      >
        <span>
          <Search_icon />
        </span>
        <input
          className={styles.input}
          type="text"
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e)}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </label>
    </div>
  );
};

export default Search;
