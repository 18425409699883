import React, { FC, useCallback } from 'react';

import style from './style.module.scss';
import styles from '../styles.module.scss';

type Props = {
  text: string | number;
  active: () => void;
  disabled?: boolean;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  transparent?: boolean;
  quiz?: boolean;
};

const Button: FC<Props> = ({
  text,
  active,
  disabled,
  className,
  size = 'medium',
  transparent = false,
  quiz = false,
}) => {
  const click = useCallback(active, [active]);
  return (
    <button
      onClick={click}
      disabled={disabled}
      className={[
        styles.button,
        style.button,
        styles[size],
        className,
        transparent && styles.transparent,
        quiz && styles.quiz,
      ].join(' ')}
    >
      {text}
    </button>
  );
};

export default Button;
