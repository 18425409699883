import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { DefaultPropsInputText } from '../type';

import styles from '../styles.module.scss';

import { ReactComponent as Error } from '../../../../assets/svg/status/error.svg';

const InputText: FC<DefaultPropsInputText> = ({
  value,
  setValue,
  placeholder,
  disabled,
  title = null,
  necessary,
  errorMessage,
  error,
  className,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [errorIs, setError] = useState<boolean>(false);

  const blurError = () => {
    if (necessary) {
      setError(!value ? true : error || false);
    }

    setFocus(false);
  };

  useEffect(() => {
    setError(error || false);
  }, [error]);

  return (
    <div
      className={[styles.container, title && styles.withTitle, className].join(
        ' ',
      )}
    >
      {title && (
        <span className={styles.title}>
          {title} {necessary && <span className={styles.necessary}>*</span>}
        </span>
      )}
      <label
        className={
          [
            focus && styles.active,
            disabled && styles.disabled,
            errorIs && styles.error,
            errorIs && styles.box,
          ].join(' ') || ''
        }
      >
        <input
          type="text"
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e)}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={blurError}
        />
        {errorIs && <span className={styles.flexCenter}>{<Error />}</span>}
      </label>
      {errorIs && <p className={styles.descriptionError}>{errorMessage}</p>}
    </div>
  );
};

export default InputText;
