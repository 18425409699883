import { useContext } from 'react';
import { AlertContext } from '../context/alert';

const useAlert = () => {
  const context = useContext(AlertContext);

  if (context === undefined)
    throw new Error('useModal must be used within a UserProvider');

  return context;
};

export default useAlert;
