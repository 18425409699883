import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';

import style from './style.module.scss';

import { StatusSteps } from '../../type/form';
import { title } from 'process';

const list = [
  {
    title: '🔥 Біокамін це...',
    describe: [
      'Біокаміни - це пристрої, які створюють атмосферу затишку та тепла за допомогою біоетанолу, екологічно чистого палива, яке не виділяє диму, сажі та запаху. ',
      'Біокамін створює атмосферу, запалюйте його наодинці з коханою людиною або в колі друзів за вечерею. А ще додайте сюди гарну музику та настільну гру і ваш вечір буде незабутнім',
    ],
  },
  {
    title: '❗️ Як користуватись біокаміном?',
    describe: [
      'Зніміть дерев’яну кришечку. Oбережно налийте біопаливо в пальник, який знаходиться в середині біокаміна. Обов’язково перевірте чи біопаливо не пролилось за межі біокаміну!',
      'Підпаліть пальник запальничко. Протягом 15 хв полум’я дуже гарно розігрівається та горить орієнтовно 1 годину. Пальник розрахований на таку кількість біопалива.',
      'Догорає самостійно. Проте, якщо вам хочеться затушити його раніше, просто накрийте біокамін дерев’яною кришечкою',
    ],
  },
  {
    title: '❌ Що забороняєтться робити',
    describe: [
      'Не доливати біопаливо в палаючий біокамін. Перед цим потрібно ОБОВ’ЯЗКОВО його загасити і тільки потім обережно наливати!',
      'Під час горіння, сама чаша нагрівається, тому після горіння ми не торкаємось та не беремо чашу, адже вона буде гарячою, їй потрібно дати час для вистигання і потім брати та переміщати',
      'Не ставити запалений біокамін біля легкозаймистих речей (сухі квіти, штори, фіранки і т.д.) Розміщайте запалений біокамін на відстані не менше 50 см від легкозаймистих речей',
      'Не сушіть волосся, речі над вогнем, не підпалюйте вогнем сторонні предмети, не кидайте у пальник сторонні предмети',
      'Не залишати дітей та тварин біля вогню без нагляду та не допускати дітям самостійне користування біокаміном. Тримати біокамін, біопаливо, запальничку або сірники подалі від дітей та тварин',
      'Не залишайте вогонь без нагляду на довгий час та використовуйте біокамін тільки з підставкою',
    ],
  },
  {
    title: '📜 Історія',
    describe: [
      'Спочатку змішуються компоненти для заливки бетонної чаші. Все заливаємо у форму та ставимо в сухе місце на 1 день, після сушки, дістаємо чашу та коригуємо її до ідеального стану',
      'Кришка та підставка виготовляється з сухої дошки ясена. Після цього шліфуємо, лакуємо та сушимо.',
      'Всі інші компоненти замовляємо у виробника, такі як біопаливо, запальничка та серцевина.',
      'В середньому виробництво 1 біокаміна займаде до 3-х днів.',
    ],
  },
];

type Props = {
  setStatusSteps: Dispatch<SetStateAction<StatusSteps>>;
};

const Firepalce: FC<Props> = ({ setStatusSteps }) => {
  useEffect(() => {
    setStatusSteps((prev: StatusSteps) => ({
      ...prev,
      fireplase: true,
    }));
  }, []);

  const BlockList = () => {
    return list.map((item, id) => {
      const describe = item.describe.map(text => (
        <span key={text}>{text}</span>
      ));

      return (
        <div className={style.item}>
          <p>{item.title}</p>
          {describe}
        </div>
      );
    });
  };

  return <div className={style.container}>{BlockList()}</div>;
};

export default Firepalce;
